import './App.css';
import MenuPage from './components/MenuPage';

function App() {
  return (
    <MenuPage />
  );
}

export default App;
